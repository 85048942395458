









































































































import Vue from 'vue';
import UserSetPasswordRequestViewModel from '@glittr/frontend-core/src/services/viewModel/resource/UserSetPasswordRequestViewModel';
import UploadDocumentRequestViewModel from '@/src/services/viewModel/request/Documents/UploadDocumentRequestViewModel';
import GlaromatUserResourceViewModel from '@/src/services/viewModel/resource/GlaromatUserResourceViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    companyLogo: [] as File[],
    resetPassword: new UserSetPasswordRequestViewModel(),
    requestError: undefined as any,
    resetPasswordValidationMessage: (undefined as undefined | string),
    glaromatUser: new GlaromatUserResourceViewModel(),
    isLogoLoading: false,
    isSaving: false,
  }),
  computed: {
    user(): any {
      // TODO: Use proper User class once it is exported from core
      return this.$auth.user ?? {} as any;
    },
  },
  async mounted() {
    this.$set(this.$store.values, 'title', 'USER PROFILE');
    this.loadGlaromatUser();
  },
  methods: {
    async loadGlaromatUser() {
      this.glaromatUser = await this.$service.api.me.getGlaromatUser();
      await this.loadCompanyLogo();
    },
    async loadCompanyLogo() {
      if (this.glaromatUser.companyLogoDocumentId) {
        this.isLogoLoading = true;
        try {
          const imgBlob = await this.$service.api.documents.downloadImage(this.glaromatUser.companyLogoDocumentId);
          this.companyLogo = [imgBlob as File];
        } catch (_e: any) {
          const e:Error = _e;
          this.requestError = e.message;
        }
        this.isLogoLoading = false;
      }
    },
    async doLogoff() {
      try {
        await this.$auth.logout();
        this.$router.push('/_exterior/login');
      } catch (error) {
        this.requestError = `${error}`;
      }
    },
    async save() {
      this.isSaving = true;
      try {
        await this.$service.api.me.updateGlaromatUser(this.glaromatUser);
      } catch (_e: any) {
        const e:Error = _e;
        this.requestError = e.message;
      }
      if (this.companyLogo[0]) {
        const request = new UploadDocumentRequestViewModel();
        request.uploadFile = this.companyLogo[0] as File;
        const fileId = await this.$service.api.documents.uploadDocument(request);
        await this.$service.api.me.setProfilePicture(fileId!);
      }
      this.isSaving = false;
    },
    async doResetPassword() {
      try {
        this.resetPasswordValidationMessage = undefined;
        this.resetPassword.email = this.user.email!;
        // Todo - can be validated through control
        if (!this.resetPassword.newPassword || this.resetPassword.newPassword.length < 6) {
          this.resetPasswordValidationMessage = this.$t('core.page.profile.label.passwordTooShort');
          return;
        }
        if (this.resetPassword.newPassword !== this.resetPassword.repeatNewPassword) {
          this.resetPasswordValidationMessage = this.$t('core.page.profile.label.passwordsDoNotMatch');
          return;
        }

        await this.$service.api.core.me.setPassword(this.resetPassword)
          .then(() => {
            this.doLogoff();
          });
      } catch (error) {
        this.requestError = error;
      }
    },
  },
});
